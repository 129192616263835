import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import SensorSetup from 'modules/sensorStatus/SensorStatus'
import Packaging from 'modules/packaging/Packaging'
import Orders from 'modules/orders/Orders'
import Inventory from 'modules/inventory/Inventory'
import Customers from 'modules/customers/Customers'
import CustomerPage from 'modules/customers/CustomerPage'
import { SensorInsight } from 'modules/sensorsInsight/SensorInsight'
import * as GQL from 'generated/graphql'
import 'react-toastify/dist/ReactToastify.css'
import { getDomain } from 'util/tools'
import FullLoader from 'components/Loader/FullLoader'
import { hasSavedLoginToken, removeLoginToken } from 'util/auth'
import styled from 'styled-components'
import VisiblePanesContext from 'components/plasmic/reg_cal/PlasmicGlobalVariant__VisiblePanes'
import Navbar from 'components/Navbar'
import * as Sentry from '@sentry/react'
import AllSensors from 'modules/allSensors/AllSensors'
import SensorModal from 'modules/sensorStatus/SensorModal'

const GeneralWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
`
const ContentWrapper = styled.div`
  overflow: auto;
`
const Version = styled.div`
  position: absolute;
  right: 10px;
  top: 0;
  color: #ffffff36;
  font-size: 10px;
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
`

const HeaderWrapper = styled.div`
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  z-index: 100;
  width: 100%;
  overflow: visible;
`

const Error = styled.div`
  font-size: 24px;
  color: red;
`

const MainLayout = () => {
  const { data, loading, error } = GQL.useMe()

  if (loading) {
    return <FullLoader color='white' />
  }

  if (!data?.me?.id || !hasSavedLoginToken()) {
    if (!data?.me?.id) removeLoginToken()
    const domain = window.location.protocol + '//login.' + getDomain() + (window.location.hostname.includes('localhost') ? ':3001' : window.location.port)
    window.location.href = domain
    return null
  }

  if (error) {
    return (
      <div style={{ backgroundColor: '#181d36', width: '100%', height: '100vh' }}>
        <Error>
          Error, try to refresh the page or contact support.
          <br />
          <br />
          <code>{error?.message}</code>
        </Error>
      </div>
    )
  }

  const isStaff = data?.me?.isStaff || false

  Sentry.setUser({
    id: data?.me?.id,
    username: data?.me?.username,
    email: data?.me?.email,
    Staff: isStaff,
  })

  return (
    <VisiblePanesContext.Provider value={isStaff ? 'all' : 'factory'}>
      <GeneralWrapper>
        <HeaderWrapper>
          <Navbar />
          {<Version>{process.env.REACT_APP_CI_COMMIT_REF_NAME || 'staging'}</Version>}
        </HeaderWrapper>
        <ContentWrapper>
          <Routes>
            <Route path='/sensor-status' element={<SensorSetup />} />
            <Route path='/packaging' element={<Packaging />} />
            <Route path='/orders' element={<Orders />} />
            <Route path='/inventory' element={<Inventory />} />
            <Route path='/customers/:customerId' element={<CustomerPage />} />
            <Route path='/customers' element={<Customers />} />
            <Route path='/all-sensors' element={<AllSensors />} />
            <Route path='/sensor-insight' element={<SensorInsight />} />
            <Route path='*' key='*' element={<Navigate to='/sensor-status' />} />
          </Routes>
        </ContentWrapper>
        <SensorModal />
      </GeneralWrapper>
    </VisiblePanesContext.Provider>
  )
}

export default MainLayout
