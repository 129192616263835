import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import * as GQL from 'generated/graphql'
import CornerLoader from 'components/Loader/CornerLoader'
import { displayToast } from 'util/tools'
import SensorModalInside from './SensorModalInside'
import CacheConfigs from 'util/cacheConfig'

const Wrapper = styled.div`
  /* transform: scale(0); */
  height: 0px;
  box-sizing: border-box;
  width: auto;
  max-width: 850px;
  animation: fadein 0.1s linear;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 80px;
  animation-fill-mode: both;
  overflow: hidden;
  overflow-x: hidden;
  z-index: 102;
  max-height: 85%;

  @keyframes fadein {
    0% {
      height: 0%;
      overflow: hidden;
    }
    99.9% {
      overflow: hidden;
      height: 85%;
    }
    100% {
      height: auto;
      overflow-y: auto;
    }
  }
`
const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.45);
`

export default function SensorModal() {
  const navigate = useNavigate()
  const location = useLocation()

  const [sensor, { data, loading, error }] = GQL.useSensorLazyQuery({
    ...CacheConfigs.ACCURATE_FREQUENT_NO_POLL,
  })

  const query = new URLSearchParams(location.search)
  const sensorId = query.get('sensor')

  useEffect(() => {
    if (sensorId) {
      sensor({ variables: { id: sensorId } })
    }
  }, [sensor, sensorId])

  const onClose = () => {
    navigate(location.pathname)
  }

  if (sensorId) {
    if (loading && !data?.sensor) {
      return (
        <>
          <CornerLoader size={65} />
          <Overlay onClick={onClose} />
        </>
      )
    }
    if (error) {
      displayToast(error?.message || 'There has been error fetching sensor data')
      return null
    }
    if (data?.sensor) {
      return (
        <>
          <Wrapper
            onKeyDown={function (e) {
              if (e.key === 'Escape') {
                onClose()
              }
            }}
          >
            <SensorModalInside sensor={data.sensor as GQL.SensorNode} />
          </Wrapper>
          <Overlay onClick={onClose} />
        </>
      )
    }
    return null
  }
  return null
}
