import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import MainLayout from './MainLayout'
import client from 'apollo'
import { PlasmicRootProvider } from '@plasmicapp/react-web'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'
import { IntlProvider } from 'react-intl'
import messages from 'locale/messages'

const StyledToastContainer = styled(ToastContainer).attrs({
  autoClose: 3000,
  position: 'top-center',
  icon: false,
  closeButton: false,
  style: {
    width: 'auto',
  },
  hideProgressBar: true,
  closeOnClick: false,
})`
  .Toastify__toast-container {
  }
  .Toastify--animate {
    animation-duration: 0.5s;
  }
  .Toastify__toast {
    cursor: inherit;
    font-family: 'Circular';
    background: rgba(255, 255, 255, 0);
    box-shadow: none;
    padding: 0;
    margin-bottom: 0.75em;
    min-height: 10px;
  }
  .Toastify__toast-body {
    padding: 0;
    margin: 0;
    margin-top: 10rem important!;
  }
`

const language = localStorage.getItem('language') || 'en'
let finalMessages = messages[language]
if (!finalMessages) {
  finalMessages = messages['en']
}

const Root = () => (
  <ApolloProvider client={client}>
    <IntlProvider locale={language} messages={finalMessages}>
      <PlasmicRootProvider>
        <StyledToastContainer />
        <Router>
          <MainLayout />
        </Router>
      </PlasmicRootProvider>
    </IntlProvider>
  </ApolloProvider>
)

export default Root
