import React from 'react'
import './index.css'
import Root from './containers/Root'
import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'

import Modal from 'react-modal'
Modal.setAppElement('#root')

if (process.env.REACT_APP_ENVIRONMENT !== 'dev') {
  console.log('Setting up sentry')
  Sentry.init({
    dsn: 'https://0bf07a40fd294b439e5045a7ef17f2bd@o241718.ingest.sentry.io/5953723',
    environment: process.env.REACT_APP_ENVIRONMENT || 'unknown',
    release: 'regcal@' + (process.env.REACT_APP_CI_COMMIT_REF_NAME || 'local') + '-' + (process.env.REACT_APP_CI_JOB_ID || '0'),
  })
}
console.log('Process env: ', process.env || 'unknown')

const root = createRoot(document.getElementById('root'))
root.render(<Root />)
