import { WatchQueryOptions } from '@apollo/client'

const MINUTE = 60000

const CacheConfigs = {
  DEFAULT: {
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    pollInterval: 0,
  } as const satisfies Partial<WatchQueryOptions>,
  ACCURATE_FREQUENT: {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    pollInterval: 5 * MINUTE,
  } as const satisfies Partial<WatchQueryOptions>,
  ACCURATE_FREQUENT_NO_POLL: {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
  } as const satisfies Partial<WatchQueryOptions>,
  ACCURATE_ONCE: {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    pollInterval: 0,
  } as const satisfies Partial<WatchQueryOptions>,
} as const

export default CacheConfigs
