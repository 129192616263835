export function modalStyle() {
  const common = {
    overlay: {
      zIndex: 8000,
      background: 'rgba(0, 0, 0, 0.45)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      margin: '0 auto',
      left: 'unset',
      right: 'unset',
      top: 'unset',
      bottom: 'unset',
      border: 'none',
      background: 'transparent',
      padding: '0rem',
    },
  }
  return { ...common }
}
