import { ApolloClient, InMemoryCache, HttpLink, ApolloLink, from } from '@apollo/client'
import { getLoginToken } from 'util/auth'
import { onError } from '@apollo/client/link/error'

import * as Sentry from '@sentry/react'
import CacheConfigs from 'util/cacheConfig'

const errorLink = onError(({ graphQLErrors, networkError, response, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (isStaging() || isProd()) {
        Sentry.captureException(new Error('[' + (path || []).join('/') + '] ' + message))
      }
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, locations)
      return null
    })
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    Sentry.captureException(new Error('Network error' + networkError))
  }

  return forward(operation)
})

export const API_URL = isLocal()
  ? 'http://localhost:8020'
  : isDev()
    ? 'https://api.widar.dev.lpg.ninja'
    : isStaging()
      ? 'https://api.widar.staging.lpg.ninja'
      : isProd()
        ? 'https://api.smartcylinders.com'
        : ''

const httpLink = new HttpLink({
  uri: API_URL + '/graphql',
})

function isEnv(env: string) {
  return process.env.REACT_APP_ENVIRONMENT === env
}

export function isLocal() {
  return isEnv('local')
}

export function isStaging() {
  return isEnv('staging')
}

export function isDev() {
  return isEnv('dev')
}

export function isProd() {
  return isEnv('prod')
}

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getLoginToken()

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : '',
      'system-origin': 'RegCal',
    },
  })

  return forward(operation)
})

const logMiddleware = new ApolloLink((operation, forward) => {
  if (isStaging() || isProd()) {
    Sentry.addBreadcrumb({
      category: 'gql',
      message: '[ QUERY ] ' + operation.operationName + ' (' + JSON.stringify(operation.variables) + ')',
      level: 'info' as Sentry.SeverityLevel,
    })
  }
  return forward(operation)
})

const link = from([errorLink, logMiddleware, authMiddleware, httpLink])

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: CacheConfigs.DEFAULT,
    query: CacheConfigs.DEFAULT,
  },
})

export default client
