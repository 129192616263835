import { Cookies } from 'react-cookie'

export const LOGIN_TOKEN_KEY = 'login-token'

export const getRootDomain = () => {
  const domain = window.location.hostname.split('.')
  return domain.slice(Math.max(domain.length - 2, 0), domain.length).join('.')
}

export function setLoginToken(token: string) {
  return new Cookies().set(LOGIN_TOKEN_KEY, token as string, {
    path: '/',
    domain: '.' + getRootDomain(),
    maxAge: 60 * 60 * 24 * 30,
  })
}

export function getLoginToken(): string | null {
  return new Cookies().get(LOGIN_TOKEN_KEY)
}

export function hasSavedLoginToken(): boolean {
  return getLoginToken() !== undefined
}

export function removeLoginToken() {
  return new Cookies().remove(LOGIN_TOKEN_KEY, { path: '/', domain: '.' + getRootDomain() })
}
