// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import 'tippy.js/dist/tippy.css'
import { PlasmicSensorId, DefaultSensorIdProps } from './plasmic/reg_cal/PlasmicSensorId'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { useNavigate } from 'react-router-dom'
import * as GQL from 'generated/graphql'
import styled from 'styled-components'
import Tippy, { TippyProps } from '@tippyjs/react'
import 'tippy.js/dist/tippy.css'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface SensorIdProps extends Omit<DefaultSensorIdProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultSensorIdProps altogether and have
// total control over the props for your component.
interface SensorIdProps extends DefaultSensorIdProps {
  title?: any
  sensor?: GQL.SensorNode
}

interface ITooltipProps extends TippyProps {
  background?: string
  style?: any
}

export const Tooltip = styled(Tippy)<ITooltipProps>`
  background: #212843;
  box-shadow: 0 6px 15px 0 #111830;
  padding: 2px 5px;
  border-radius: 10px;
  max-width: 300px !important;
  font-size: 12px;
  display: flex;
  width: auto;
  justify-content: center;
  .tippy-arrow {
    color: #2b3457;
  }
  .tippy-content {
    width: 100%;
  }
`

function SensorId_(props: SensorIdProps, ref: HTMLElementRefOf<'div'>) {
  const navigate = useNavigate()
  // Use PlasmicSensorId to render this component as it was
  // designed in Plasmic, by activating the appropriate variants,
  // attaching the appropriate event handlers, etc.  You
  // can also install whatever React hooks you need here to manage state or
  // fetch data.
  //
  // Props you can pass into PlasmicSensorId are:
  // 1. Variants you want to activate,
  // 2. Contents for slots you want to fill,
  // 3. Overrides for any named node in the component to attach behavior and data,
  // 4. Props to set on the root node.
  //
  // By default, we are just piping all SensorIdProps here, but feel free
  // to do whatever works for you.
  const { sensor, ...rest } = props

  const showColor = () => {
    if (sensor?.flags?.some(e => e?.flag === 'WRECKED')) return 'red'
    if (sensor?.cgSide?.id) return 'purple'
    if (sensor?.distributor?.id) return 'gray'
    if (sensor?.flags?.some(e => e?.flag === 'END_TEST_SUCCEEDED')) return 'green'
    return 'green'
  }

  const content = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'left' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
        <div>
          <PlasmicSensorId color='blue' id={'XXXXX'} />
        </div>
        <div>Sensor in production or status unknown</div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
        <div>
          <PlasmicSensorId color='green' id={'XXXXX'} />
        </div>
        <div>Sensor ready to be packed/final test done or packed</div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
        <div>
          <PlasmicSensorId color='gray' id={'XXXXX'} />
        </div>
        <div>Sensor is assigned or shipped to ditributor but not yet installed.</div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
        <div>
          <PlasmicSensorId color='purple' id={'XXXXX'} />
        </div>
        <div>Sensor is installed at customer's cgSide</div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
        <div>
          <PlasmicSensorId color='red' id={'XXXXX'} />
        </div>
        <div>Sensor is wrecked or there is other problem. See status/comment for details.</div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
        <div>
          <PlasmicSensorId color='empty' id={''} />
        </div>
        <div>No sensor</div>
      </div>
    </div>
  )

  return (
    <Tooltip content={content} duration={0} placement='right' delay={[500, 0]}>
      <div>
        <PlasmicSensorId
          root={{ ref }}
          onClick={() => {
            if (sensor?.id && sensor.id.length > 6) {
              navigate(`?sensor=${sensor.id}`)
            }
          }}
          id={sensor?.serialNumber || 'NoData'}
          color={showColor() as 'blue' | 'green' | 'orange' | 'red' | 'purple' | 'empty' | 'gray' | undefined}
          {...rest}
        />
      </div>
    </Tooltip>
  )
}

const SensorId = React.forwardRef(SensorId_)
export default SensorId
