/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { modelFlag, showSensors, displayToast, useFocus, useDebounce, displayFormatedDateTime } from 'util/tools'
import * as GQL from 'generated/graphql'
import PlasmicPackaging from 'components/Packaging'
import FullLoader from 'components/Loader/FullLoader'
import TableRow from 'components/TableRow'
import CellBoxId from 'components/CellBoxId'
import CellModel from 'components/CellModel'
import CellSensorId from 'components/CellSensorId'
import CellCreated from 'components/CellCreated'
import CellPrint from 'components/CellPrint'
import ButtonFill from 'components/ButtonFill'
import ButtonEdit from 'components/ButtonEdit'
import Sidebar from 'components/Sidebar'
import InputField from 'components/InputField'
import ReactModal from 'react-modal'
import { modalStyle } from 'components/Modal/Modal'
import CellActions from 'components/CellActions'
import DropdownAction from 'components/DropdownAction'
import ModalDelete from 'components/ModalDelete'
import CornerLoader from 'components/Loader/CornerLoader'
import EditBoxModal from 'modules/components/EditBoxModal'
import { FormattedMessage, useIntl } from 'react-intl'
import CacheConfigs from 'util/cacheConfig'

export interface IEditBoxData {
  boxName?: string | null
  boxId?: string | null
  sensors?: any
}

export function Packaging() {
  const [boxModelName, setBoxModelName] = useState<string | undefined>(undefined)
  const [search, setSearch] = useState<string>('')
  const [sensorIds, setSensorIds] = useState<string[]>(['', '', '', '', '', '', '', '', ''])
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const [deleteData, setDeleteData] = useState<any>({})
  const [editBoxModalOpen, setEditBoxModalOpen] = useState<boolean>(false)
  const [editBoxData, setEditBoxData] = useState<IEditBoxData>({})
  const [clickedActions, setClickedActions] = useState<string>('')

  const debouncedSearch = useDebounce(search)
  const [inputRef, setInputFocus] = useFocus()

  const intl = useIntl()
  const t = intl.formatMessage

  // hook for actions
  const ref = useRef<any>()
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (clickedActions && ref.current && !ref.current.contains(e.target)) {
        setClickedActions('')
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [clickedActions])

  // enter press to submit data
  // useEffect(() => {
  //   const listener = (event: any) => {
  //     if ((event.code === 'Enter' || event.code === 'NumpadEnter')) {
  //       event.preventDefault()
  //       if (sensorIds.filter(e => e !== '').length === 9) {
  //         submitNewBoxData()
  //       }
  //     }
  //   }
  //   document.addEventListener('keydown', listener)
  //   return () => {
  //     document.removeEventListener('keydown', listener)
  //   }
  // })
  // GQL
  const { data, loading } = GQL.useAllBoxes({
    ...CacheConfigs.ACCURATE_FREQUENT_NO_POLL,
    variables: {
      isShipped: false,
      isAllocated: false,
      locationName: 'Norautron',
      q: debouncedSearch,
    },
    onError: err => {
      displayToast('Error while fetching boxes data!')
    },
  })

  const { data: allLocationsData } = GQL.useAllBoxLocations()
  const norautronLocationID = allLocationsData?.allBoxLocations?.find(e => e?.name.toLowerCase() === 'norautron')?.id

  const { data: nextBoxData, loading: nextBoxDataLoading } = GQL.useNextBoxId({ notifyOnNetworkStatusChange: true, ...CacheConfigs.ACCURATE_FREQUENT_NO_POLL })
  const nextBoxId = nextBoxData?.nextBoxId
  const { data: lastBoxData } = GQL.useLastBox({ notifyOnNetworkStatusChange: true, ...CacheConfigs.ACCURATE_FREQUENT_NO_POLL })
  const lastBox = lastBoxData?.allBoxes?.edges[0]

  const listData = useMemo(
    () =>
      data?.allBoxes?.edges?.map((box: any) => (
        <TableRow
          boxId={<CellBoxId color={box.node.isShipped ? 'shipped' : box.node.allocatedDate ? 'allocated' : undefined}>{box.node.name}</CellBoxId>}
          model={
            <CellModel symbol={modelFlag(box.node.sensors?.find((e: GQL.SensorNode) => e.model?.name)?.model?.name ?? undefined)}>
              {box.node.sensors?.find((e: GQL.SensorNode) => e.model?.name)?.model?.name ?? 'undefined'}
            </CellModel>
          }
          sensorIDs={
            <CellSensorId>
              {showSensors(box.node.sensors)}
              <ButtonEdit
                onClick={() => {
                  const temp = []
                  if (box.node.sensors.length < 9) {
                    for (let i = 0; i < 9 - box.node.sensors.length; i++) {
                      temp.push('')
                    }
                  }
                  setEditBoxData({
                    boxName: box.node.name,
                    boxId: box.node.id,
                    sensors: [...box.node.sensors?.map((e: any) => e.serialNumber), ...temp],
                  })
                  setEditBoxModalOpen(true)
                }}
              />
            </CellSensorId>
          }
          created={<CellCreated>{displayFormatedDateTime(box.node.createdAt || null)}</CellCreated>}
          print={
            <CellPrint>
              <ButtonFill
                title={t({ id: 'packaging.print_label' })}
                target='_blank'
                buttonClass='rowHoverButton'
                buttonId='printButton'
                destination={`https://uploaded-static.s3.amazonaws.com/resource-center/regcal/p700-box-label-002.html?boxID=${box.node.name}&modelNr=${
                  box.node.sensors[0]?.model?.name
                }&qtyStr=${box.node.sensors.length}%20Pcs&serial1=${box.node.sensors
                  .map((e: any, i: number) => {
                    if (i === 0) return e.serialNumber
                    if (i === 5) return '&serial2=' + e.serialNumber
                    return ',%20' + e.serialNumber
                  })
                  .join('')}`}
              />
            </CellPrint>
          }
          key={box.node.id}
          actions={
            <CellActions
              open={clickedActions === box.node.id ? true : false}
              ref={clickedActions === box.node.id ? ref : null}
              onClick={() => (clickedActions === box.node.id ? setClickedActions('') : setClickedActions(box.node.id))}
              actions={
                <>
                  <DropdownAction
                    onClick={(e: any) => {
                      e.stopPropagation()
                      setDeleteModalOpen(true)
                      setDeleteData({ id: box.node.id, name: box.node.name })
                      setClickedActions('')
                    }}
                  >
                    ❌ {t({ id: 'common.delete' })}
                  </DropdownAction>
                </>
              }
            />
          }
          visibleColumns={['boxId', 'model', 'sensorIDs', 'created', 'print', 'actions']}
        />
      )),
    [data?.allBoxes?.edges, clickedActions, ref]
  )

  const [checkSensorIfExist] = GQL.useAllSensorsLazyQuery({
    ...CacheConfigs.ACCURATE_FREQUENT_NO_POLL,
    onError: err => {
      displayToast(err.message)
    },
  })

  const [decodeSensorSerial] = GQL.useDecodeSensorSerialLazyQuery({
    ...CacheConfigs.ACCURATE_FREQUENT_NO_POLL,
    onError: err => {
      displayToast(err.message)
      return
    },
  })
  // GQL MUTATIONS
  const [deleteBox] = GQL.useDeleteBox({
    refetchQueries: ['AllBoxes'],
    onError: err => {
      displayToast('There was an error deleting box!')
      console.log(err)
    },
    onCompleted: ({ deleteBox }: any) => {
      if (deleteBox === null) {
        displayToast('Denied! Try again or contact support.')
        return
      }
      const { ok } = deleteBox
      if (!ok) {
        displayToast('Server error. Try again or contact support.')
        return
      }
      onRequestCloseDeleteModal()
      displayToast(<FormattedMessage id='packaging.box_deleted' />, 'success', { toastId: null })
    },
  })

  const [createBox] = GQL.useCreateBox({
    refetchQueries: ['AllBoxes', 'LastBox', 'NextBoxId'],
    onCompleted: ({ createBox }: any) => {
      if (createBox === null) {
        displayToast('Denied! Try again or contact support.')
        return
      }
      const ok = createBox.ok
      if (!ok) {
        createBox.error ? displayToast(createBox.error) : displayToast('Server error. Try again or contact support.')
        return
      }
      setInputFocus()
      window.open(
        `https://uploaded-static.s3.amazonaws.com/resource-center/regcal/p700-box-label-002.html?boxID=${nextBoxId}&modelNr=${
          boxModelName ?? 'UNKNOWN'
        }&qtyStr=${sensorIds.filter(e => e !== 'EMPTY').length}%20Pcs&serial1=${sensorIds
          .filter(e => e !== 'EMPTY')
          .map((e, i) => {
            if (i === 0) return e
            if (i === 5) return '&serial2=' + e
            return ',%20' + e
          })
          .join('')}`,
        '_blank'
      )
      setSensorIds(['', '', '', '', '', '', '', '', ''])
    },
    onError: (err: any) => {
      displayToast(err.message)
    },
  })

  // LOCAL FUNCTIONS

  const checkAndAssignSensorToSensorArray = (sensor: GQL.SensorNode) => {
    if (sensorIds.filter(e => e !== '').length === 9) {
      displayToast(<FormattedMessage id='packaging.sensor_array_full' />)
      return null
    }
    if (sensorIds.some(e => e === sensor.serialNumber)) {
      displayToast(<FormattedMessage id='packaging.sensor_already_added' />)
      return null
    }
    if (!sensor.flags?.some(e => e?.flag === 'END_TEST_SUCCEEDED')) {
      displayToast(<FormattedMessage id='packaging.not_ready_to_be_packed' />, 'warning')
      return
    }
    if (sensor.flags?.some(e => e?.flag === 'WRECKED')) {
      displayToast(
        <span>
          Scanned sensor is <b>WRECKED!</b> <br />
          It is not possible to pack such a sensors!
        </span>,
        'error'
      )
      return
    }
    if (sensor?.box?.id) {
      displayToast(
        <FormattedMessage id='packaging.sensor_already_in_box' values={{ serialNumber: sensor.serialNumber, box: sensor.box.name, newLine: '\n' }} />,
        'warning',
        {
          toastId: null,
          closeOnClick: true,
          autoClose: 4000,
        }
      )
    }
    if (sensor.model?.name && !boxModelName) {
      setBoxModelName(sensor.model.name)
    }
    setSensorIds(old => {
      const temp = [...old]
      temp.splice(temp.indexOf(''), 1, sensor.serialNumber)
      return temp
    })
    return
  }

  function assignFromSmartToFields(id: string) {
    if (id.length < 5) {
      displayToast('Input data is too short')
      return null
    }
    if (id) {
      if (id === 'EMPTY') {
        if (sensorIds.filter(e => e !== '').length === 9) {
          displayToast(<FormattedMessage id='packaging.sensor_array_full' />)
          return null
        }
        setSensorIds(old => {
          const temp = [...old]
          temp.splice(temp.indexOf(''), 1, id)
          return temp
        })
      }
      if (id.includes('http') || id.includes('id=')) {
        // Extract id
        const extraction = id.match(/id=([a-zA-Z0-9_=-]+)/)
        if (!extraction || extraction.length === 0) {
          displayToast('There is no sensor ID in scanned sequence!')
          return null
        }
        console.log(extraction[1])
        decodeSensorSerial({ variables: { sensorSerial: extraction[1] } }).then(e => {
          if (e?.data?.decodeSensorSerial?.ok === false) {
            displayToast('Wrong sensor ID!')
            return
          }
          if (e?.error) {
            displayToast('Error!')
            return null
          }
          if (!e?.data?.decodeSensorSerial?.sensor) {
            displayToast('Error!')
            return null
          }
          checkAndAssignSensorToSensorArray(e?.data?.decodeSensorSerial?.sensor as GQL.SensorNode)
        })
        return null
      }
      checkSensorIfExist({ variables: { serialNumber: id.toUpperCase(), first: 1 } }).then(e => {
        if (e.error) {
          displayToast('Error!')
          return null
        }
        if (!e.data?.allSensors?.edges || e?.data?.allSensors?.edges.length === 0) {
          displayToast('Sensor ID not recognized!')
          return null
        }
        checkAndAssignSensorToSensorArray(e?.data?.allSensors?.edges[0]?.node as GQL.SensorNode)
      })
      return null
    }
    displayToast('Unknown input!')
    return null
  }

  function submitNewBoxData() {
    if (sensorIds.filter(e => e !== '').length < 9) {
      displayToast(<FormattedMessage id='packaging.need_9' />)
      return
    }
    createBox({
      variables: {
        location: norautronLocationID,
        sensors: sensorIds
          .filter(e => e !== 'EMPTY')
          .map(sensorId => {
            return { id: sensorId }
          }),
      },
    })
  }

  const onRequestCloseEditBoxModal = () => {
    setEditBoxData({})
    setEditBoxModalOpen(false)
  }
  const onRequestCloseDeleteModal = () => {
    setDeleteData({})
    setDeleteModalOpen(false)
  }
  return (
    <>
      {loading && <CornerLoader size={32} topAdjust='-15px' />}
      {/* Delete box confirmation modal */}
      <ReactModal isOpen={deleteModalOpen} onRequestClose={onRequestCloseDeleteModal} style={modalStyle()}>
        <ModalDelete
          closeModal={{ onClick: () => onRequestCloseDeleteModal() }}
          btnDelete={{
            onClick: () => {
              deleteBox({ variables: { id: deleteData.id } })
            },
          }}
          objectTitle={'box ' + deleteData.name}
        />
      </ReactModal>

      {/* Edit box modal */}
      <EditBoxModal onClose={onRequestCloseEditBoxModal} isOpen={editBoxModalOpen} editBoxData={editBoxData} setEditBoxData={setEditBoxData} />
      <PlasmicPackaging
        sidebar={
          <Sidebar
            boxIdField={{
              props: {
                value: nextBoxId ? nextBoxId : nextBoxDataLoading ? '' : 'Error',
                onChange: () => null,
              },
            }}
            newBoxLabel={<FormattedMessage id='packaging.new_box' />}
            scanInputPackaging={{
              props: {
                ref: inputRef,
                autoFocus: true,
                onKeyDown: (e: any) => {
                  if (e.key === 'Tab' || e.key === 'Enter' || e.code === 'NumpadEnter') {
                    if (sensorIds.filter(e => e !== '').length === 9) {
                      submitNewBoxData()
                    } else {
                      e.preventDefault()
                      assignFromSmartToFields(inputRef.current.value)
                      inputRef.current.value = ''
                    }
                  }
                },
              },
            }}
            sensorIDs={
              <>
                {[...Array(9)].map((el, i) => (
                  <div
                    key={i + 'sensorfields'}
                    onClick={() => {
                      if (sensorIds[i] === '') return null
                      const temp = [...sensorIds]
                      temp.splice(i, 1, '')
                      setSensorIds([...temp])
                    }}
                  >
                    <InputField
                      style={{ pointerEvents: 'none' }}
                      disabled={true}
                      readOnly={true}
                      green={sensorIds[i] && sensorIds[i] !== '' ? true : false}
                      sensorId
                      value={sensorIds[i]}
                    />
                  </div>
                ))}
              </>
            }
            registerButton={{
              label: t({ id: 'packaging.register_label' }),
              underLabel: t({ id: 'packaging.register_under_label' }),
              disabled: sensorIds.filter(e => e !== '').length === 9 ? false : true,
              onClick: () => {
                submitNewBoxData()
              },
            }}
            lastRegistered={{
              props: {
                boxId: lastBox?.node?.name ?? null,
                date: displayFormatedDateTime(lastBox?.node?.createdAt || null),
              },
            }}
            lastRegisteredLabel={t({ id: 'packaging.last_registered_label' })}
          />
        }
        searchField={{ value: search, onChange: (e: any) => setSearch(e.target.value), placeholder: t({ id: 'packaging.search' }) }}
        searchBlock={{ notEmpty: !!search, clear: { onClick: () => setSearch('') } }}
        boxIdHeader={t({ id: 'common.box_id' })}
        sensorsHeader={t({ id: 'packaging.sensors' })}
        createdHeader={t({ id: 'common.created_at' })}
        printHeader={t({ id: 'common.print' })}
        rows={<>{loading && !data ? <FullLoader color='white' /> : <>{listData}</>}</>}
      />
    </>
  )
}

export default Packaging
