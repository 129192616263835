// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import * as React from 'react'
import { PlasmicCellBoxId, DefaultCellBoxIdProps } from './plasmic/reg_cal/PlasmicCellBoxId'
import { HTMLElementRefOf } from '@plasmicapp/react-web'
import { Tooltip } from './SensorId'

// Your component props start with props for variants and slots you defined
// in Plasmic, but you can add more here, like event handlers that you can
// attach to named nodes in your component.
//
// If you don't want to expose certain variants or slots as a prop, you can use
// Omit to hide them:
//
// interface CellBoxIdProps extends Omit<DefaultCellBoxIdProps, "hideProps1"|"hideProp2"> {
//   // etc.
// }
//
// You can also stop extending from DefaultCellBoxIdProps altogether and have
// total control over the props for your component.
interface CellBoxIdProps extends DefaultCellBoxIdProps {}

// const BoxInfoWrapper = styled.div`
//   position: fixed ;
//   display: flex ;
//   justify-content:center ;
//   align-items:center ;
//   left:0;
//   top:0;
//   z-index: 1901;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.212);
// `
// const BoxInfo = styled.div`
//   width: 300px;
//   height: 300px;
//   background-color: #757575;
//   border-radius: 25px;
// `

function CellBoxId_(props: CellBoxIdProps, ref: HTMLElementRefOf<'div'>) {
  const content = (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', alignItems: 'left' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
        <div>
          <PlasmicCellBoxId>BIDXXX</PlasmicCellBoxId>
        </div>
        <div>Box is not allocated nor shipped</div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
        <div>
          <PlasmicCellBoxId color='allocated'>BIDXXX</PlasmicCellBoxId>
        </div>
        <div>Box is allocated to ditributor but not yet shipped</div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center', gap: '1rem' }}>
        <div>
          <PlasmicCellBoxId color='shipped'>BIDXXX</PlasmicCellBoxId>
        </div>
        <div>Box is shipped to ditributor</div>
      </div>
    </div>
  )

  return (
    <>
      <Tooltip content={content} duration={0} placement='right' delay={[500, 0]} disabled={!!props.blank}>
        <div>
          <PlasmicCellBoxId root={{ ref }} {...props} onClick={() => console.log('BoxClicking')} />{' '}
        </div>
      </Tooltip>
      {/* <BoxInfoWrapper>
    <BoxInfo></BoxInfo>
    </BoxInfoWrapper> */}
    </>
  )
}

const CellBoxId = React.forwardRef(CellBoxId_)
export default CellBoxId
