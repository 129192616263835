import React, { useEffect, useState } from 'react'
import * as GQL from 'generated/graphql'
import { default as PlasmicSensorSetup } from 'components/SensorStatus'
import { currentSensorStatus } from 'util/tools'
import SensorId from 'components/SensorId'
import CornerLoader from 'components/Loader/CornerLoader'
import { useIntl } from 'react-intl'
import { parseISO } from 'date-fns'
import CacheConfigs from 'util/cacheConfig'

export function SensorSetup() {
  const [allSensors, setAllSensors] = useState<GQL.SensorNode[]>([])
  const [filteredSensors, setFilteredSensors] = useState<GQL.SensorNode[]>([])

  const [orderBy, setOrderBy] = useState<string>('-createdAt')
  const [search, setSearch] = useState<string>('')

  const intl = useIntl()
  const t = intl.formatMessage

  const getOnlyValidSensors = (sensors: GQL.SensorNode[]) => {
    return sensors.filter(
      e => (currentSensorStatus(e) === 'finalTestDone' || currentSensorStatus(e) === 'calibrationComplete') && !e?.flags?.some(e => e?.flag === 'HIDDEN')
    )
  }

  const getOrderedSensorList = (unorderedList: GQL.SensorNode[], type: GQL.SensorFlagEnum) => {
    if (orderBy !== 'serialNumber') {
      // created at flag sort
      return unorderedList.sort((a, b) => {
        return (
          parseISO(b.flags?.find(e => e?.flag === type)?.createdAt || '').getTime() - parseISO(a?.flags?.find(e => e?.flag === type)?.createdAt || '').getTime()
        )
      })
    }

    // alphanumeric order sort
    return unorderedList.sort(function (a, b) {
      return a.serialNumber.localeCompare(b.serialNumber, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    })
  }

  // GQL QUERIES

  const { loading } = GQL.useAllSensors({
    ...CacheConfigs.ACCURATE_FREQUENT,
    notifyOnNetworkStatusChange: true,
    variables: {
      boxed: false,
      createdAtGt: new Date(2019, 1, 1),
      flags: ['END_TEST_SUCCEEDED', 'CALIBRATED'],
    },
    onCompleted: response => {
      if (response.allSensors?.edges) {
        // all valid sensors - "END_TEST_SUCCEEDED" and "CALIBRATED" flags only
        const validSensors = getOnlyValidSensors(response.allSensors.edges.map(sensor => sensor?.node as GQL.SensorNode))

        setAllSensors(validSensors)

        // if re-fetched while searching
        if (!search) {
          setFilteredSensors(validSensors)
          return
        }

        // all valid sensors filtered
        setFilteredSensors(validSensors.filter((sensor: GQL.SensorNode) => sensor.serialNumber.toLowerCase().includes(search.toLowerCase())))
      }
    },
  })

  useEffect(() => {
    if (search) {
      const searched = getOnlyValidSensors(allSensors).filter((sensor: GQL.SensorNode) => sensor.serialNumber.toLowerCase().includes(search.toLowerCase()))
      setFilteredSensors(searched)
      return
    }
    // if no search - return all valid sensors
    setFilteredSensors(getOnlyValidSensors(allSensors))
  }, [allSensors, search])

  return (
    <>
      {loading && <CornerLoader size={32} topAdjust='-15px' />}
      <PlasmicSensorSetup
        colCalibrationComplete={{
          count: filteredSensors.filter(e => currentSensorStatus(e as GQL.SensorNode) === 'calibrationComplete').length.toString(),
          content: getOrderedSensorList(
            filteredSensors.filter(e => currentSensorStatus(e as GQL.SensorNode) === 'calibrationComplete'),
            GQL.SensorFlagEnum.Calibrated
          ).map(e => <SensorId key={e?.serialNumber} sensor={e as GQL.SensorNode} />),
          title: t({ id: 'common.calibration_complete' }),
        }}
        colError={{
          // errorContent: <></>,
          count: 0,
          content: <></>,
          title: t({ id: 'common.error' }),
        }}
        colDone={{
          count: filteredSensors.filter(e => currentSensorStatus(e as GQL.SensorNode) === 'finalTestDone').length.toString(),
          content: getOrderedSensorList(
            filteredSensors.filter(e => currentSensorStatus(e as GQL.SensorNode) === 'finalTestDone'),
            GQL.SensorFlagEnum.EndTestSucceeded
          ).map(e => <SensorId key={e.serialNumber} sensor={e as GQL.SensorNode} />),
          title: t({ id: 'common.done' }),
        }}
        searchField={{ value: search, onChange: (e: any) => setSearch(e.target.value), placeholder: t({ id: 'common.search_by_id' }) }}
        searchBlock={{ notEmpty: !!search, clear: { onClick: () => setSearch('') } }}
        sortToggle={{
          newestFirst: orderBy === '-createdAt',
          slider: { onClick: (e: any) => setOrderBy('serialNumber') },
          slider2: { onClick: (e: any) => setOrderBy('-createdAt') },
          title: t({ id: 'common.sort_by.alphabetical' }),
          title2: t({ id: 'common.sort_by.newest_first' }),
        }}
      />
    </>
  )
}

export default SensorSetup
