/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { displayFormattedDate, displayToast, modelFlag, showSensors, useDebounce } from 'util/tools'

import * as GQL from 'generated/graphql'
import PlasmicInventory from 'components/Inventory'
import TableRow from 'components/TableRow'
import CellBoxId from 'components/CellBoxId'
import CellModel from 'components/CellModel'
import CellSensorId from 'components/CellSensorId'
import CellLocation from 'components/CellLocation'
import CellProduction from 'components/CellProduction'
import CellStatus from 'components/CellStatus'
import FullLoader from 'components/Loader/FullLoader'
import OrderDropzone from 'components/OrderDropzone'
import CellSelectRow from 'components/CellSelectRow'
import Checkbox from 'components/Checkbox'
import ButtonEdit from 'components/ButtonEdit'
// eslint-disable-next-line camelcase
import Select__Option from 'components/Select__Option'
import CellActions from 'components/CellActions'
import DropdownAction from 'components/DropdownAction'
import CornerLoader from 'components/Loader/CornerLoader'
import EditBoxModal from 'modules/components/EditBoxModal'
import { useIntl } from 'react-intl'
import CacheConfigs from 'util/cacheConfig'

interface editBoxData {
  boxName?: string | null
  boxId?: string | null
  sensors?: any
}

export function Inventory() {
  const [filterSlider, setFilterSlider] = useState<{ eu: boolean; au: boolean; a910: boolean }>({ eu: false, au: false, a910: false })
  const [search, setSearch] = useState<string>('')
  const [ordersCollapsed, setOrdersCollapsed] = useState<boolean>(false)
  const [selectedBoxOrder, setSelectedBoxOrder] = useState<string>('')
  const [selectedBoxes, setSelectedBoxes] = useState<Array<string>>([])
  const [editBoxModalOpen, setEditBoxModalOpen] = useState<boolean>(false)
  const [editBoxData, setEditBoxData] = useState<editBoxData>({})
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [clickedActions, setClickedActions] = useState<string>('')

  const actionsRef = useRef<any>()

  const debouncedSearch = useDebounce(search, 1000)

  const intl = useIntl()
  const t = intl.formatMessage

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (clickedActions && actionsRef.current && !actionsRef.current.contains(e.target)) {
        setClickedActions('')
      }
    }
    document.addEventListener('mousedown', checkIfClickedOutside)
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside)
    }
  }, [clickedActions])

  const getSensorModelName = () => {
    if (filterSlider.eu) {
      return 'A-901-EU'
    }

    if (filterSlider.au) {
      return 'A-901-AU'
    }

    if (filterSlider.a910) {
      return 'A-910'
    }

    return ''
  }

  // GQL QUERIES
  const { data: ordersData } = GQL.useAllBoxOrders({
    variables: { isCompleted: false },
    ...CacheConfigs.ACCURATE_FREQUENT_NO_POLL,
    onError: err => {
      displayToast('Problem with fetching box orders!')
      console.log(err.message)
    },
    onCompleted: data => {
      if (data.allBoxOrders?.edges.length === 0) {
        setOrdersCollapsed(true)
      }
    },
    notifyOnNetworkStatusChange: true,
  })

  const boxLocations = GQL.useAllBoxLocations({
    ...CacheConfigs.ACCURATE_FREQUENT_NO_POLL,
    onError: err => displayToast('Error Fetching Locations'),
  })?.data?.allBoxLocations?.map((e: any) => {
    return { loc: e.name, id: e.id }
  })

  const { data, loading } = GQL.useAllBoxes({
    ...CacheConfigs.ACCURATE_FREQUENT_NO_POLL,
    notifyOnNetworkStatusChange: true,
    variables: {
      isAllocated: false,
      q: debouncedSearch,
      sensorModelName: getSensorModelName(),
    },
    onError: err => {
      displayToast(t({ id: 'common.error_fetching_box_data' }))
      console.log(err)
    },
  })

  // GQL MUTATIONS
  const [patchBox] = GQL.usePatchBox({
    notifyOnNetworkStatusChange: true,
    onError: (err: any) => {
      console.log(err)
      displayToast(t({ id: 'common.error_fetching_box_data' }))
    },
    onCompleted: ({ patchBox }: any) => {
      if (patchBox === null) {
        displayToast(t({ id: 'common.denied_try_again' }))
        return
      }
      const { ok, error } = patchBox
      if (!ok) {
        error ? displayToast(error) : displayToast(t({ id: 'common.server_error' }))
        return
      }
      setEditBoxModalOpen(false)
      displayToast(t({ id: 'common.box_edited_successfully' }), 'success', { toastId: null })
    },
  })

  const [assignBoxesToOrder] = GQL.useAssignBoxesToOrder({
    onError: err => {
      console.log(err.message)
      displayToast('Error assigning boxes to order!')
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    refetchQueries: ['AllBoxes', 'AllBoxOrders'],
    onCompleted: ({ assignBoxesToOrder }: any) => {
      if (assignBoxesToOrder === null) {
        displayToast(t({ id: 'common.denied_try_again' }))
        return
      }
      const { ok, error } = assignBoxesToOrder
      if (!ok) {
        error ? displayToast(error) : displayToast(t({ id: 'common.server_error' }))
        return
      }
      setSelectedBoxes([])
      setSelectedBoxOrder('')
      setSelectAll(false)
      displayToast(t({ id: 'common.success_assigning_boxes' }), 'success')
    },
  })

  const showStatus = (box: any) => {
    if (box.isShipped) return 'shipped'
    if (box.sensors.length < 9) return 'missingSensor'
    return undefined
  }

  const onRequestClose = () => {
    setEditBoxData({})
    setEditBoxModalOpen(false)
  }

  const dataList = useMemo(
    () =>
      data?.allBoxes?.edges?.map((box: any) => {
        return (
          <TableRow
            key={box.node.id}
            select={
              <CellSelectRow
                aria-label='Distributor'
                selectRow={
                  <Checkbox
                    aria-label='checkbox'
                    isChecked={selectedBoxes.includes(box.node.id) ? true : false}
                    onChange={e => {
                      if (e) {
                        setSelectedBoxes([...selectedBoxes, box.node.id])
                      } else {
                        const temp = selectedBoxes.filter(element => element !== box.node.id)
                        setSelectedBoxes(temp)
                      }
                    }}
                    children={null}
                  />
                }
              />
            }
            boxId={<CellBoxId color={box.node.isShipped ? 'shipped' : box.node.allocatedDate ? 'allocated' : undefined}>{box.node.name}</CellBoxId>}
            model={
              <CellModel symbol={modelFlag(box.node.sensors.find((e: GQL.SensorNode) => e.model?.name)?.model.name ?? undefined)}>
                {box.node.sensors.find((e: GQL.SensorNode) => e.model?.name)?.model.name ?? 'undefined'}
              </CellModel>
            }
            actions={
              <>
                <CellActions
                  open={clickedActions === box.node.id ? true : false}
                  ref={clickedActions === box.node.id ? actionsRef : null}
                  onClick={() => (clickedActions === box.node.id ? setClickedActions('') : setClickedActions(box.node.id))}
                  actions={
                    <>
                      <DropdownAction
                        onClick={(e: any) => {
                          e.stopPropagation()
                          window.open(
                            `https://uploaded-static.s3.amazonaws.com/resource-center/regcal/testSVG.html?boxID=${box.node.name}&modelNr=${
                              box.node.sensors[0]?.model?.name || box.node.sensors[1]?.model?.name
                            }
              &serial1=${box.node.sensors[0]?.serialNumber || 'MISSING'},%20${box?.node.sensors[1]?.serialNumber || 'MISSING'},%20${
                box?.node.sensors[2]?.serialNumber || 'MISSING'
              },%20${box?.node.sensors[3]?.serialNumber || 'MISSING'}
              &serial2=${box?.node.sensors[4]?.serialNumber || 'MISSING'},%20${box?.node.sensors[5]?.serialNumber || 'MISSING'},%20${
                box?.node.sensors[6]?.serialNumber || 'MISSING'
              }`,
                            '_blank'
                          )
                          setClickedActions('')
                        }}
                      >
                        🖨️ {t({ id: 'inventory.print_label' })}
                      </DropdownAction>
                      {box.node.isShipped && (
                        <DropdownAction
                          onClick={(e: any) => {
                            e.stopPropagation()
                            patchBox({
                              variables: {
                                boxId: box.node.id,
                                input: { isShipped: false },
                              },
                              refetchQueries: ['AllBoxes'],
                            })
                            setClickedActions('')
                          }}
                        >
                          🚚 {t({ id: 'inventory.mark_unshipped' })}
                        </DropdownAction>
                      )}
                    </>
                  }
                />
              </>
            }
            sensorIDs={
              <CellSensorId>
                {showSensors(box.node.sensors)}
                <ButtonEdit
                  onClick={() => {
                    const temp = []
                    if (box.node.sensors.length < 9) {
                      for (let i = 0; i < 9 - box.node.sensors.length; i++) {
                        temp.push('')
                      }
                    }
                    setEditBoxData({
                      boxName: box.node.name,
                      boxId: box.node.id,
                      sensors: [...box.node.sensors?.map((e: any) => e.serialNumber), ...temp],
                    })
                    setEditBoxModalOpen(true)
                  }}
                />
              </CellSensorId>
            }
            location={
              <CellLocation
                selectLocation={{
                  props: {
                    label: 'Location select',
                    'aria-label': 'Location select',
                    onChange: (e: any) =>
                      patchBox({
                        variables: {
                          boxId: box.node.id,
                          input: { location: e },
                        },
                      }),
                    defaultValue: box?.node?.location?.id,
                    children: boxLocations?.map(e => (
                      <Select__Option key={e.id} value={e.id}>
                        {e.loc}
                      </Select__Option>
                    )),
                  },
                }}
              />
            }
            production={<CellProduction>{displayFormattedDate(new Date(box.node.createdAt))}</CellProduction>}
            status={<CellStatus statuses={showStatus(box.node)} />}
            visibleColumns={['boxId', 'model', 'sensorIDs', 'location', 'production', 'actions', 'status', 'select']}
          />
        )
      }),
    [data?.allBoxes?.edges, selectedBoxes, clickedActions, boxLocations]
  )

  return (
    <>
      {loading && <CornerLoader size={32} topAdjust='-15px' />}
      <EditBoxModal onClose={onRequestClose} isOpen={editBoxModalOpen} editBoxData={editBoxData} setEditBoxData={setEditBoxData} />
      <PlasmicInventory
        checkAll={{
          props: {
            'aria-label': 'Select all boxes',
            isChecked: selectAll,
            onChange: (e: boolean) => {
              if (e) {
                setSelectAll(true)
                setSelectedBoxes(data?.allBoxes?.edges.map(e => e?.node?.id!) ?? [])
                return
              }
              setSelectedBoxes([])
              setSelectAll(false)
            },
          },
        }}
        toggleA910={{
          props: {
            onClick: () => setFilterSlider({ a910: !filterSlider.a910, au: false, eu: false }),
            number: data && filterSlider.a910 ? data.allBoxes?.edges.length : null,
            active: filterSlider.a910,
          },
        }}
        toggleA901Au={{
          props: {
            onClick: () => setFilterSlider({ au: !filterSlider.au, eu: false, a910: false }),
            number: data && filterSlider.au ? data.allBoxes?.edges.length : null,
            active: filterSlider.au,
          },
        }}
        toggleA901Eu={{
          props: {
            onClick: () => setFilterSlider({ eu: !filterSlider.eu, au: false, a910: false }),
            number: data && filterSlider.eu ? data.allBoxes?.edges.length : null,
            active: filterSlider.eu,
          },
        }}
        searchField={{
          value: search,
          onChange: (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.target.value),
          placeholder: t({ id: 'common.search' }),
        }}
        searchBlock={{ notEmpty: !!search, clear: { onClick: () => setSearch('') } }}
        headLocation={t({ id: 'common.location' })}
        headProduction={t({ id: 'common.production' })}
        filterModels={t({ id: 'common.models' })}
        rows={<>{loading && !data ? <FullLoader color='white' /> : <>{dataList}</>}</>}
        ordersDrawer={{
          props: {
            onClick: (e: any) => {
              if (ordersCollapsed) {
                setOrdersCollapsed(!ordersCollapsed)
              }
            },
            headUndeliveredOrders: {
              props: {
                children: t({ id: 'common.undelivered_orders' }),
              },
            },
            collapsed: ordersCollapsed,
            collapse: { onClick: () => setOrdersCollapsed(!ordersCollapsed) },
            btnAllocate: {
              color: selectedBoxOrder && selectedBoxes.length > 0 ? 'green' : 'disabled',
              title: t({ id: 'common.allocate' }),
              onClick: () => {
                if (selectedBoxOrder && selectedBoxes.length > 0) {
                  assignBoxesToOrder({
                    variables: {
                      boxOrder: selectedBoxOrder,
                      boxes: selectedBoxes.map((e: any) => {
                        return { id: e }
                      }),
                    },
                  })
                  // setSelectedBoxOrder('');
                  // setSelectedBoxes([]);
                  // setSelectAll(false);
                } else {
                  displayToast(t({ id: 'inventory.select_box' }))
                }
              },
            },
            orders: (
              <>
                {ordersData?.allBoxOrders?.edges?.map(order => {
                  return (
                    <OrderDropzone
                      style={{ cursor: 'pointer' }}
                      key={order?.node?.id}
                      onClick={() => setSelectedBoxOrder(order?.node?.id ?? '')}
                      selected={selectedBoxOrder === order?.node?.id ? true : false}
                      customer={order?.node?.distributor.fullName}
                      boxes={order?.node?.boxesOrdered! - order?.node?.boxesAllocated!}
                      model={order?.node?.requestedModel?.name}
                      orderNumber={order?.node?.id ? window.atob(order?.node?.id).split(':')[1] : 'Undef'}
                    />
                  )
                })}
              </>
            ),
          },
        }}
      />
    </>
  )
}

export default Inventory
